import {useCallback, useEffect, useMemo, useState} from 'react';

export const useLocalStorage = (key: string, initialValue?: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const memoizedStoredValue = useMemo(() => storedValue, [storedValue]);

  const deleteFromLocalStorage = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
      setStoredValue(undefined);
    } catch (error) {
      console.error(error);
    }
  }, [key]);

  useEffect(() => {
    try {
      if (memoizedStoredValue) {
        window.localStorage.setItem(key, JSON.stringify(memoizedStoredValue));
      }
    } catch (error) {
      console.error(error);
    }
  }, [key, memoizedStoredValue]);

  return [memoizedStoredValue, setStoredValue, deleteFromLocalStorage];
};
