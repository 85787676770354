import {FunctionComponent, useMemo} from 'react';

import {SnackbarType} from './type';

interface SnackbarProps {
  type: SnackbarType;
  message: string;
  onClose: () => void;
}

export const Snackbar: FunctionComponent<SnackbarProps> = ({
  type,
  message,
  onClose
}) => {
  const snackType = useMemo((): string => {
    let snackCSS = 'p-4 rounded shadow-lg w-full sm:w-80 pointer-events-auto';

    switch (type) {
      case 'INFO':
        snackCSS += ' bg-blue-600 text-white';
        break;
      case 'SUCCESS':
        snackCSS += ' bg-green-600 text-white';
        break;
      case 'WARNING':
        snackCSS += ' bg-yellow-600 text-white';
        break;
      case 'ERROR':
        snackCSS += ' bg-red-400 dark:bg-red-600';
        break;
    }

    return snackCSS;
  }, [type]);

  return (
    <div className={snackType}>
      <div className="flex justify-between items-center">
        <p className="text-left">{message}</p>
        <button
          className="hover:text-gray-400 focus:outline-none"
          onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
