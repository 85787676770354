import {FunctionComponent, ReactNode} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {Loader} from '../loader/loader';
import {useUser} from '../../contexts';

interface ProtectedRouteProps {
  permissionLevel: number;
  redirectPath?: string;
  children: ReactNode;
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  permissionLevel,
  redirectPath = '/error?statusCode=404',
  children
}: ProtectedRouteProps) => {
  const {user, isLoading} = useUser();

  if (isLoading) {
    return <Loader />;
  }

  if (!user?.email || user?.permissionLevel < permissionLevel) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};
