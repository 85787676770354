interface MergeClassNamesProps {
  conditions?: {[key: string]: boolean};
}

/**
 * Generate classnames based on conditional props
 * Useful to create conditional styling
 *
 * @params conditions | Take a className as [key] and the condition as [value]
 */
export const mergeClassNames = (opts: MergeClassNamesProps) => {
  const {conditions = {}} = opts;
  return Object.keys(conditions)
    .filter((conditionClass) => conditions[conditionClass])
    .join(' ');
};
