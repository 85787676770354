import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Dialog, Popover} from '@headlessui/react';
import {Bars3Icon, LightBulbIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

import {useUser} from '../../contexts';
import {useLocalStorage} from '../../hooks';

export const Navbar: FunctionComponent = () => {
  const {user} = useUser();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [, setTheme] = useLocalStorage('preferedTheme');

  const toggleTheme = useCallback((): void => {
    const htmlDiv = document.getElementsByTagName('html')[0];

    if (htmlDiv.classList.contains('dark')) {
      htmlDiv.classList.remove('dark');
      setTheme('light');
    } else {
      htmlDiv.classList.add('dark');
      setTheme('dark');
    }
  }, [setTheme]);

  const redirectPage = useMemo(() => (user ? '/dashboard' : '/login'), [user]);

  return (
    <header className="shadow-xl bg-red-500 dark:bg-zinc-900">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global">
        <div className="flex lg:flex-1">
          <Link
            to="/"
            className="-m-1.5 p-1.5 select-none rounded-full bg-rouge-mouton">
            <span className="sr-only">Le mouton</span>
            <img className="h-8 w-auto" src="/icon_binary.png" alt="logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <Popover.Group className="hidden lg:flex lg:gap-x-12 lg:items-center">
          <Link to="/" className="text-sm font-semibold leading-6">
            Accueil
          </Link>
          <Link to="/about" className="text-sm font-semibold leading-6">
            A propos
          </Link>
          <Link
            to={redirectPage}
            className="bg-gradient-to-r from-emerald-200 to-emerald-950 dark:from-fuchsia-500 dark:to-cyan-500 text-transparent bg-clip-text animate-gradient-x text-sm font-semibold leading-6">
            Staff Only
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button
            onClick={toggleTheme}
            className="-m-2.5 rounded-full p-2.5 hover:bg-red-400 dark:hover:bg-zinc-700">
            <span className="sr-only">Change theme</span>
            <LightBulbIcon
              className="h-6 w-6 text-slate-50 dark:text-red-500"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>

      <Dialog
        as="div"
        className="lg:hidden z-30"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 bg-red-500 dark:bg-zinc-900">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              className="-m-1.5 p-1.5 select-none rounded-full bg-rouge-mouton">
              <span className="sr-only">Le mouton</span>
              <img className="h-8 w-auto" src="/icon_binary.png" alt="logo" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6 text-slate-50" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-slate-400">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base text-neutral-900 dark:text-slate-50 font-semibold leading-7 hover:bg-red-600 dark:hover:bg-zinc-700">
                  Accueil
                </Link>
                <Link
                  to="/about"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base text-neutral-900 dark:text-slate-50 font-semibold leading-7 hover:bg-red-600 dark:hover:bg-zinc-700">
                  A propos
                </Link>
                <Link
                  to={redirectPage}
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base text-neutral-900 dark:text-slate-50 font-semibold leading-7 hover:bg-red-600 dark:hover:bg-zinc-700">
                  <div className="bg-gradient-to-r from-emerald-200 to-emerald-950 dark:from-fuchsia-500 dark:to-cyan-500 text-transparent bg-clip-text animate-gradient-x">
                    Staff Only
                  </div>
                </Link>
              </div>
              <div className="py-6">
                <button
                  onClick={toggleTheme}
                  className="-m-2.5 rounded-full p-2.5 hover:bg-red-600 dark:hover:bg-zinc-700">
                  <span className="sr-only">Change theme</span>
                  <LightBulbIcon
                    className="h-6 w-6 text-slate-50 dark:text-red-500"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
