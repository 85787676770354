import {FunctionComponent, ReactNode} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {Loader} from '../loader/loader';
import {useUser} from '../../contexts';

interface NotLoggedOnlyRouteProps {
  redirectPath?: string;
  children: ReactNode;
}

export const NotLoggedOnlyRoute: FunctionComponent<NotLoggedOnlyRouteProps> = ({
  redirectPath = '/',
  children
}: NotLoggedOnlyRouteProps) => {
  const {user, isLoading} = useUser();

  if (isLoading) {
    return <Loader />;
  }

  if (user?.email) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};
