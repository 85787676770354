import {QRCode} from 'react-qrcode-logo';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import useDebounce from '../../hooks/useDebounce';

export interface QrCodeProps {
  name: string;
  uuid: string;
  color: string;
  showDownload?: boolean;
}

export const QrCode: FunctionComponent<QrCodeProps> = ({
  name,
  uuid,
  color,
  showDownload = true
}) => {
  const [bgColor, setBgColor] = useState<string>(color || '#E41F28');

  useDebounce(
    () => {
      setBgColor(color);
    },
    showDownload ? 0 : 500,
    [color, showDownload]
  );

  const downloadCode = useCallback((): void => {
    const canvas: any = document.getElementById('QRCODE');
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = name ? `QR_Mouton_${name}.png` : 'QR_Mouton.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [name]);

  const downloadButton = useMemo(
    () =>
      showDownload ? (
        <button
          onClick={downloadCode}
          className="rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600 text-white">
          Télécharger le QR
        </button>
      ) : null,
    [showDownload, downloadCode]
  );

  return (
    <div className="flex flex-col items-center gap-4 py-4">
      <QRCode
        id="QRCODE"
        value={`${window.location.origin}/QR?code=${uuid}`}
        logoImage="./icon_binary.png"
        removeQrCodeBehindLogo={true}
        bgColor={bgColor}
        size={256}
      />
      {downloadButton}
    </div>
  );
};
