import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';
import {
  SnackbarState,
  SnackbarType
} from '../components/snackbars/components/snackbar/type';

export interface SnackbarProviderInterface {
  children: ReactNode;
}

interface SnackbarContextType {
  snackbars: SnackbarState[];
  addSnackbar: (message: string, type?: SnackbarType) => void;
  removeSnackbar: (id: number) => void;
}

const SnackbarContext = createContext<SnackbarContextType>({
  snackbars: [],
  addSnackbar: () => {},
  removeSnackbar: () => {}
});
export const SnackbarProvider: FunctionComponent<SnackbarProviderInterface> = ({
  children
}) => {
  const [snackbars, setSnackbars] = useState<SnackbarState[]>([]);

  const removeSnackbar = useCallback((id: number): void => {
    setSnackbars((prevSnackbars) =>
      prevSnackbars.filter((snackbar) => snackbar.id !== id)
    );
  }, []);

  const addSnackbar = useCallback(
    (message: string, type: SnackbarType = 'INFO'): void => {
      const newSnackbar: SnackbarState = {
        id: Math.floor(Math.random() * 10000),
        type: type,
        message: message
      };

      setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);

      setTimeout(() => {
        removeSnackbar(newSnackbar.id);
      }, 10000);
    },
    [removeSnackbar]
  );

  return (
    <SnackbarContext.Provider value={{snackbars, addSnackbar, removeSnackbar}}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
