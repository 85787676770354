export const httpFetch = (httpRequest: {
  body?: {[key: string]: unknown};
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  url: string;
  options?: RequestInit;
}): Promise<any> => {
  const defaultOptions: RequestInit = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  };

  const token = window.localStorage.getItem('token') || null;
  if (token) {
    defaultOptions.headers = {
      ...defaultOptions.headers,
      Authorization: `Bearer ${JSON.parse(token)}`
    };
  }

  const concatOptions = httpRequest.options
    ? {
        ...defaultOptions,
        headers: {
          ...defaultOptions.headers,
          ...httpRequest.options.headers
        }
      }
    : defaultOptions;

  return fetch(httpRequest.url, {
    body: JSON.stringify(httpRequest.body),
    method: httpRequest.method,
    ...concatOptions
  }).then(async (response: any) => {
    return {status: response.status, message: await response.json()};
  });
};
