export const Loader = () => {
  return (
    <div className="flex flex-col gap-6 h-full">
      <main className="flex-grow text-center overflow-y-auto">
        <div className="flex items-center justify-center h-full select-none pointer-events-none">
          <svg
            className="animate-spin h-40 w-40 text-red-500 dark:text-slate-50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span className="sr-only">Le mouton</span>
          <img
            className="h-32 w-auto absolute"
            src="/icon_binary.png"
            alt="logo"
          />
        </div>
      </main>
    </div>
  );
};
