import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import {httpFetch} from '../utils';
import {useLocalStorage} from '../hooks';

export type User = {
  email: string;
  name: string;
  permissionLevel: number;
};

const UserContext = createContext<{
  user: User | undefined;
  isLoading: boolean;
  invalidateUser: () => void;
  connectUser: (token: string) => void;
}>({
  user: undefined,
  isLoading: true,
  invalidateUser: () => {},
  connectUser: () => {}
});
export interface UserProviderInterface {
  children: ReactNode;
}

export const UserProvider: FunctionComponent<UserProviderInterface> = ({
  children
}) => {
  const [token, setToken, deleteFromLocalStorage] = useLocalStorage('token');
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const hasFetched = useRef(false);

  const fetchUser = useCallback(
    async (token: string) => {
      const fetchedUser = await httpFetch({
        method: 'GET',
        options: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
        url: `/api/user`
      }).then(async (res) => {
        await res;
        let tempUser = {} as User;
        if (res.status === 200) {
          tempUser = {
            name: res.message.name,
            email: res.message.email,
            permissionLevel: res.message.permission_level
          };
        } else if (res.status === 401) {
          deleteFromLocalStorage();
        }
        setIsLoading(false);
        return tempUser;
      });
      setUser(fetchedUser);
    },
    [deleteFromLocalStorage]
  );

  useEffect(() => {
    if (token) {
      if (!hasFetched.current) {
        fetchUser(token).catch((e) => console.error(e));
        hasFetched.current = true;
      }
    } else {
      setIsLoading(false);
    }
  }, [token, user, fetchUser]);

  const connectUser = useCallback(
    async (token: string): Promise<void> => {
      setToken(token);
      await fetchUser(token);
    },
    [fetchUser, setToken]
  );

  const invalidateUser = useCallback((): void => {
    setUser(undefined);
    deleteFromLocalStorage();
  }, [deleteFromLocalStorage]);

  return (
    <UserContext.Provider
      value={{user, isLoading, invalidateUser, connectUser}}>
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => useContext(UserContext);
