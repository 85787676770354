import {lazy, Suspense, useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {
  Loader,
  Navbar,
  NotLoggedOnlyRoute,
  ProtectedRoute,
  Snackbars
} from './components';
import {SnackbarProvider, UserProvider} from './contexts';
import {useLocalStorage} from './hooks';

const HomePage = lazy(() => import('./pages/home/homePage'));
const ErrorPage = lazy(() => import('./pages/error/errorPage'));
const AboutPage = lazy(() => import('./pages/about/aboutPage'));
const QrFoundPage = lazy(() => import('./pages/qrFound/qrFoundPage'));
const RegisterPage = lazy(() => import('./pages/register/registerPage'));
const LoginPage = lazy(() => import('./pages/login/loginPage'));
const DashboardPage = lazy(() => import('./pages/dashboard/dashboardPage'));
const GeneratorPage = lazy(() => import('./pages/generator/generatorPage'));
const UseQrPage = lazy(() => import('./pages/qrUse/qrUsePage'));
const QrListPage = lazy(() => import('./pages/qrList/qrListPage'));

const App = () => {
  const [theme] = useLocalStorage('preferedTheme');

  useEffect((): void => {
    if (theme) {
      if (theme === 'dark') {
        document.getElementsByTagName('html')[0].classList.add('dark');
      }
    } else {
      // set default theme
      let preferDark = window.matchMedia('(prefers-color-scheme: dark)');
      if (preferDark.matches) {
        document.getElementsByTagName('html')[0].classList.add('dark');
      }
    }
  }, [theme]);

  return (
    <Suspense fallback={<Loader />}>
      <UserProvider>
        <SnackbarProvider>
          <div className="flex flex-col h-full bg-slate-100 dark:bg-zinc-900 text-neutral-900 dark:text-slate-50">
            <Navbar />
            <main className="flex-grow text-center overflow-y-auto pt-4 lg:px-8 px-6">
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/QR" element={<QrFoundPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/error" element={<ErrorPage />} />

                  <Route
                    path="/login"
                    element={
                      <NotLoggedOnlyRoute>
                        <LoginPage />
                      </NotLoggedOnlyRoute>
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <NotLoggedOnlyRoute>
                        <RegisterPage />
                      </NotLoggedOnlyRoute>
                    }
                  />

                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute permissionLevel={0}>
                        <DashboardPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/use"
                    element={
                      <ProtectedRoute permissionLevel={1}>
                        <UseQrPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/generator"
                    element={
                      <ProtectedRoute permissionLevel={2}>
                        <GeneratorPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/list"
                    element={
                      <ProtectedRoute permissionLevel={2}>
                        <QrListPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="*"
                    element={<Navigate replace to={'/error?statusCode=404'} />}
                  />
                </Routes>
              </Suspense>
            </main>
            <Snackbars />
          </div>
        </SnackbarProvider>
      </UserProvider>
    </Suspense>
  );
};

export default App;
