import {useMemo} from 'react';

import {Snackbar} from './components/snackbar/snackbar';
import {mergeClassNames} from '../../utils';
import {useSnackbar} from '../../contexts';

export const Snackbars = () => {
  const {snackbars, removeSnackbar} = useSnackbar();

  const snackbarsStyle = useMemo(
    (): string =>
      mergeClassNames({
        conditions: {
          'fixed bottom-0 flex flex-col gap-2 items-end px-2 w-full pointer-events-none z-20':
            true,
          'pb-2': snackbars.length > 0
        }
      }),
    [snackbars]
  );

  return (
    <div className={snackbarsStyle}>
      {snackbars.map((snackbar) => (
        <Snackbar
          key={snackbar.id}
          type={snackbar.type}
          message={snackbar.message.toString()}
          onClose={() => removeSnackbar(snackbar.id)}
        />
      ))}
    </div>
  );
};
