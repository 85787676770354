import {FunctionComponent, ReactNode, useEffect, useRef} from 'react';
import {XMarkIcon} from '@heroicons/react/24/outline';

export interface AlertProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
}

export const Alert: FunctionComponent<AlertProps> = ({
  title = '',
  isOpen = false,
  onClose,
  children
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef?.current) {
      if (isOpen) {
        dialogRef.current.showModal();
      } else if (dialogRef.current.open) {
        dialogRef.current.close();
      }
    }
  }, [dialogRef, isOpen]);

  return (
    <dialog ref={dialogRef} onClick={onClose}>
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm h-full w-full">
        <div
          className="bg-white rounded-lg p-4 flex flex-col"
          onClick={(e) => e.stopPropagation()}>
          <div className="flex flex-row justify-between pb-4">
            <h3 className="font-bold">{title}</h3>
            <button onClick={onClose}>
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </dialog>
  );
};
